import React from 'react'
import Layout from '../../components/layout/layout'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeThree from "../../components/layout/sidemenu/sidemenu_se_three"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import ContentHeader from '../../components/page-sections/content-header'
import FocusQuestion from '../../components/page-sections/focus-question'
import Introduction from '../../components/page-sections/introduction'
import ButtonTop from '../../components/buttons/button-top'
import ButtonNext from '../../components/buttons/button-next'

import rowImage from '../../queries/images/row-image'

import unit3Icon from '../../images/icons/unit_03_icon.svg'
import climateModel22 from '../../images/student-pages/unit-3/22_climate_model.png'
import kidSwing23 from '../../images/student-pages/unit-3/23_kid_swing.jpg'
import costaRicaborder24 from '../../images/student-pages/unit-3/24_costa_rica_border.jpg'
import CO2Cycle25 from '../../images/student-pages/unit-3/25_co2_cycle.gif'
import carbonBudgetModel26 from '../../images/student-pages/unit-3/26_carbon_budget_model.gif'
import sourcesSink27 from '../../images/student-pages/unit-3/27_sources_sink.gif'
import light28 from '../../images/student-pages/unit-3/28_light_kids.jpg'


const FutureForcings33 = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - 3.3 Future Forcings" />
    <Container className="mb-5" fluid>
      <Row>
        <Col md="auto">
          <SideBarSeThree location={props.location.pathname} />
        </Col>
        <Col>
          <CCBreadcrumb
            pathname={props.location.pathname}
            title={'3.3 Future Forcings'}
            replace={props.replace}
          />
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
          <Card>
            <Card.Body>

              <ContentHeader
                icon={ unit3Icon }
                iconAlt="Unit 3 icon"
                studentPageHeader="Unit 3: Carbon in the Future &amp; You"
                unitNumber="3.3"
                studentPageHeaderSub="Future Forcings"
                sectionA={ <>What You Saw with the Climate Model </> }
                sectionALink="unit-3/3.3-future-forcings/#what-you-saw-with-the-climate-model"
                sectionB={ <>Carbon&mdash;Future Forcings</> }
                sectionBLink="unit-3/3.3-future-forcings/#carbon-future-forcings"
                sectionC={ <>Monitoring Your Use</> }
                sectionCLink="unit-3/3.3-future-forcings/#monitoring-your-use"
                sectionD={ <>Reflect and Connect</> }
                sectionDLink="unit-3/3.3-future-forcings/#reflect-and-connect"
              />

              <FocusQuestion
                focusQuestionBodyText={ <>How are the carbon cycle and humans a part of future climate?</> }
              />

              <Introduction
                paragraphOne={
                  <>
                    <p>In Lesson 3.2, you used the <em>Carbon Connections</em> climate model to compare forcings on Earth's temperature the past 30 years (1979-2010). You tested how four forcings explained most of the temperature record. There are several other possible factors, but the four forcings you used are the best predictors of temperature.</p>

                    <Row className="d-md-none">
                      <Col>
                        <Img
                          className="rounded img-fluid image-bordered"
                          fluid={props.data.image22.childImageSharp.fluid}
                          alt="Some climate models use a grid of cells that surround Earth. These models track the flow of matter and energy between neighboring cells by physical processes (inset). The horizontal grid shows position on Earth. The vertical grid displays height in the atmosphere. (Courtesy of NOAA.gov)"
                        />
                      </Col>
                    </Row>
                    <Row className="d-md-none">
                      <Col>
                        <div className="image-caption image-caption-bordered rounded img-fluid mb-3 mt-0 image-bordered">
                          Some climate models use a grid of cells that surround Earth. These models track the flow of matter and energy between neighboring cells by physical processes (inset). The horizontal grid shows position on Earth. The vertical grid displays height in the atmosphere. (Courtesy of NOAA.gov)
                        </div>
                      </Col>
                    </Row>
                    <div className="image-wrapper-xs">
                      <Img
                        className="image image-bordered d-none d-md-block"
                        fluid={props.data.image22.childImageSharp.fluid}
                        alt="Some climate models use a grid of cells that surround Earth. These models track the flow of matter and energy between neighboring cells by physical processes (inset). The horizontal grid shows position on Earth. The vertical grid displays height in the atmosphere. (Courtesy of NOAA.gov)"
                        />
                      <div className="image-caption image-caption-bordered d-md-block d-none">
                        Some climate models use a grid of cells that surround Earth. These models track the flow of matter and energy between neighboring cells by physical processes (inset). The horizontal grid shows position on Earth. The vertical grid displays height in the atmosphere. (Courtesy of NOAA.gov)
                      </div>
                    </div>
                  </>
                }

                paragraphTwo={
                  <>
                    <p>But what does the future hold for Earth's climate? To answer this, scientists use models to make predictions. The models consist of a grid of cells surrounding Earth, similar to the image above. The models determine the transfer of energy and matter among neighboring cells by different physical processes. Using models like this is one vital way to learn what the future might be like.</p>
                  </>
                }

                paragraphThree={
                  <>
                    <p>You will start Lesson 3.3 by summarizing some results from the <em>Carbon Connections</em> climate model. In particular, in this lesson you will see that:</p>
                    <ul>
                      <li>Variability in the Earth's system over periods of years is different from gradual change in the system over periods of decades.</li>
                      <li>Models show how Earth's climate may change in the future with changing CO<sub>2</sub> levels in the atmosphere.</li>
                    </ul>
                  </>
                }

                paragraphFour={
                  <>
                    <p>Energy-use monitors help you and your class see how to conserve energy and reduce carbon emissions.</p>
                  </>
                }
              />

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="what-you-saw-with-the-climate-model" className="cardHeader">
                      <h2>
                        A. <span className="headerFontBlue">What You Saw with the Climate Model</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Did you get to show the <em>Carbon Connections</em> climate model to someone in your family? Perhaps they were interested in the climate model and forcing by volcanic aerosols, or perhaps one of the videos of the Sun. Before you consider what might happen in the future, it is good to summarize important things you saw with the climate model. Here are some key points.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image23.childImageSharp.fluid}
                            alt="Kid on a swing"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="verticalImageRight img-fluid d-none d-md-block ml-3 mb-3"
                        fluid={props.data.image23.childImageSharp.fluid}
                        alt="Kid on a swing"
                      />

                      <p>
                        First, <strong>climate variability</strong> tells how much the temperature goes up or down over several months or years. A system can have a lot of variability, yet also have no change in the average. Think of a swing or pendulum. These vary in a back-and-forth manner, but their average position is always the middle. Similarly, the variability of solar cycles is predictable. Solar radiation increases and decreases. In contrast, the El
                        Ni&ntilde;o/La Ni&ntilde;a cycles show a lot of variability, but when the peaks and valleys occur is less predictable. Volcanic eruptions are even less predictable. These natural forcings lead to variability in temperature.
                      </p>

                      <p>
                        Second, a key question is whether the Earth's temperature is changing or staying the same. If overall temperature were increasing or decreasing over many years, that would be <strong>climate change</strong>. Scientists work to tell between climate variability on the scale of years (e.g., El Ni&ntilde;o/La Ni&ntilde;a cycles), versus climate change over decades. The data you saw in Lesson 3.1 showed a pattern of increasing temperatures, particularly the past 30 years. In Lesson 3.2, the climate model let you test which factor caused that temperature increase. It was the anthropogenic part. In contrast, the "natural" parts of climate variability (that is, solar cycles and El Ni&ntilde;o/La Ni&ntilde;a cycles) did lead to a net temperature increase over time.
                      </p>

                      <p>
                        NASA has a short video called "Taking Earth's Temperature" that illustrates some ways that they are working to monitor Earth's climate. Read the questions below and then watch the video.
                      </p>

                      <ol>
                        <li>
                          Answer the following questions after watching the NASA video:
                          <ol type="a">
                            <li>What are three major pieces of the climate puzzle that NASA is monitoring?</li>
                            <li>Which greenhouse gases are discussed in the video?</li>
                            <li>You learned about the reflectivity of volcanic aerosols with the climate model. What is the reflective aerosol discussed in the video?</li>
                          </ol>
                        </li>
                      </ol>

                      <div id="" className="embed-container">
                        <iframe title="temp_puzzle" className="videoSetting" src="https://player.vimeo.com/video/307899184" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="carbon-future-forcings" className="cardHeader">
                      <h2>
                        B. <span className="headerFontBlue">Carbon&mdash;Future Forcings</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        You used one kind of climate model to test climate forcings on Earth's temperature. This model connected increases in CO<sub>2</sub> levels in the atmosphere to humans. But you also know from Units 1 and 2 that natural processes also lead to changes in CO<sub>2</sub> in the atmosphere. So, how can you test the size of the natural and human processes on the carbon cycle?
                      </p>

                      <p>
                        At the University of Wisconsin, Professor Galen McKinley has used another kind of model to study the carbon cycle and climate. Her model estimates CO<sub>2</sub> levels and temperatures in the future. The trends in CO<sub>2</sub> level depend on changes in carbon <em>sources</em> and <em>sinks</em> in the carbon cycle. You studied in Units 1 and 2 processes that change the amount of carbon in the atmosphere. Some processes were related to humans, and others were natural. You will use Professor McKinley's model in the steps below to test the relationship between carbon and climate in the future.
                      </p>

                      <p>
                        Several key processes affect the total CO<sub>2</sub> levels in the atmosphere. In the model, two carbon sources add CO<sub>2</sub> to the atmosphere, and two carbon sinks remove CO<sub>2</sub> from the atmosphere.
                      </p>
                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid image-bordered"
                            fluid={props.data.image24.childImageSharp.fluid}
                            alt="This is a satellite that shows southern Costa Rica near the border with Panama (yellow). Patterns of land use change at the border of a national park. Forests above and to the right of the park border store more carbon in plants and soils than farmlands to the left. You can find the location: 34°29'24.33N, 91°11'27.18W."
                          />
                        </Col>
                      </Row>
                      <Row className="d-md-none">
                        <Col>
                          <div className="image-caption image-caption-bordered rounded img-fluid mb-3 image-bordered">
                            This is a satellite that shows southern Costa Rica near the border with Panama (yellow). Patterns of land use change at the border of a national park. Forests above and to the right of the park border store more carbon in plants and soils than farmlands to the left. You can find the location: 34°29'24.33N, 91°11'27.18W.
                          </div>
                        </Col>
                      </Row>
                      <div className="image-wrapper-xxs">
                        <Img
                          className="image image-bordered d-none d-md-block"
                          fluid={props.data.image24.childImageSharp.fluid}
                          alt="This is a satellite that shows southern Costa Rica near the border with Panama (yellow). Patterns of land use change at the border of a national park. Forests above and to the right of the park border store more carbon in plants and soils than farmlands to the left. You can find the location: 34°29'24.33N, 91°11'27.18W."
                        />
                        <div className="image-caption image-caption-bordered d-md-block d-none">
                          This is a satellite image that shows southern Costa Rica near the border with Panama (yellow). Patterns of land use change at the border of a national park. Forests above and to the right of the park border store more carbon in plants and soils than farmlands to the left. You can find the location: 34&deg;29'24.33"N, 91&deg;11'27.18"W.
                        </div>
                      </div>

                      <p className="lead">The Global Carbon Budget (University of Wisconsin-Madison)</p>
                      <ol start="2">
                        <li>
                          Look at the following figure. Answer the questions below about the processes shown that move CO<sub>2</sub> into and out of the atmosphere.
                        </li>
                      </ol>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid image-bordered mb-3 d-none d-md-block"
                            src={CO2Cycle25}
                            alt="This instrument monitors CO2 moving between the forest and the atmosphere above."
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter img-fluid mb-3"
                        src={CO2Cycle25}
                        alt="CO2 cycle"
                      />

                      <ol>
                        <ol type="a">
                          <li>Carbon <strong>sources</strong> add CO<sub>2</sub> to the atmosphere. Give two examples of carbon sources.</li>
                          <li>Carbon <strong>sinks</strong> remove CO<sub>2</sub> from the atmosphere. Give two examples of carbon sinks.</li>
                          <li>Recall the Mauna Loa CO<sub>2</sub> curve in <Link to="/unit-2/2.3-the-breathing-biosphere/#unit-2-mauna-loa-co2-chart">Unit 2</Link>. From that curve, do sources of carbon or sinks of carbon have a greater effect on the amount of carbon in the atmosphere?</li>
                        </ol>
                      </ol>

                      <ol start="3">
                        <li>
                          The following information will help you use the carbon budget model.
                        </li>
                        <ul className="list-unstyled">
                          <li><strong>Carbon Sinks</strong></li>
                          <li><em>Land Uptake</em>: By keeping forests and soils healthy, land uptake can lead to a net removal of CO<sub>2</sub> from the atmosphere. This occurs by photosynthesis. As you saw in Unit 2, respiration does move carbon back to the atmosphere, but the net flow of carbon is to land.</li>
                          <li><em>Ocean Uptake</em>: You saw in Unit 1 that oceans are the largest reservoir of carbon. Uptake of carbon by oceans is probably the most efficient way to remove CO<sub>2</sub> from the atmosphere. A possible downside is that CO<sub>2</sub> movement to the ocean surface can change the acidity of oceans.</li>
                        </ul> <br />

                        <ul className="list-unstyled">
                          <li><strong>Carbon Sources</strong></li>
                          <li><em>Land Use</em>: The growing use of land by humans releases carbon from plants, trees, and soils back to the atmosphere. One of the main ways this happens is by converting forests to land for growing crops or grazing. Forest fires also rapidly move carbon from land to the atmosphere.</li>
                          <li><em>Fossil Fuels</em>: Using fossil fuels is important for transportation and generating electrical energy; however, that process leads to a net flow of carbon from where it resides inside Earth to the atmosphere.</li>
                        </ul>
                      </ol>

                      <ol start="4">
                        <li>The figure below shows the first screen of the carbon budget model.</li>
                      </ol>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid image-bordered mb-3 d-none d-md-block"
                            src={ carbonBudgetModel26 }
                            alt="Sources and sinks in PgC/yr, actual and projected"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter rounded mb-4 img-fluid imageBorder"
                        src={ carbonBudgetModel26 }
                        alt="Sources and sinks in PgC/yr, actual and projected"
                      />

                      <p>
                        The left graph shows trends in carbon sources and sinks. The right graph shows projections for CO<sub>2</sub> in the atmosphere and global temperature. In the left graph, carbon <em>sources</em> have values greater than zero because carbon is added to the atmosphere. Carbon <em>sinks</em> have values that are negative because carbon is subtracted, or removed, from the atmosphere.
                      </p>

                      <p>
                        Orange parts of the lines show data from 1960 to 2010. The y-axis is petagrams of carbon per year (Pg C/yr). A petagram is a lot of carbon&mdash;in fact, one petagram equals 1,000,000,000,000,000 grams of carbon (that is, 1x10<sup>15</sup> grams of carbon). How big is that?! Well, imagine that you had some water. One gram of water is about the size of a sugar cube. But one petagram of water would cover a football field (plus end-zones) with water to a depth of 187 km. That's water 120 miles deep, or about 12 times higher than most of the atmosphere!
                      </p>

                      <p>
                        In the model, each source or sink has a green curve when you select it. The curves go from 2010 to 2100 and show a set of points. When the curve is selected, you can grab and adjust its trend to simulate different scenarios. You will test some scenarios below. Then, under "Controls," you run your projection to 2100, or reset it to the initial values.
                      </p>

                      <ol start="5">
                        <li>
                          Follow these steps for a simulation to 2100 using current projections for rate of fuel use.
                          <ol type="a">
                            <li>Launch the Global Carbon Budget.</li>
                            <li>Use the controls to click on <strong>Reset</strong>. This gives projected trends to 2100 for carbon sources and sinks.</li>
                            <li>Click on <strong>Run the projection</strong>. This will calculate estimated changes in temperature and CO<sub>2</sub> levels for those projected trends.</li>
                            <li>Record the projected CO<sub>2</sub> level for the year 2100.</li>
                          </ol>
                        </li>

                        <Row className="d-md-none">
                          <Col>
                            <img
                              className="rounded img-fluid mb-3 mt-3 imageBorder"
                              src={ sourcesSink27 }
                              alt="Sources and sinks in PgC/yr, actual and projected"
                            />
                          </Col>
                        </Row>
                        <img
                          className="smallImageRight imageBorder rounded ml-3 mt-3 mb-3 img-fluid d-none d-md-block"
                          src={ sourcesSink27 }
                          alt="Sources and sinks in PgC/yr, actual and projected"
                        />

                        <li>
                          Use the steps below for a different scenario. Do a test where the rate of using fossil fuel is reduced by half for 2100. To do that, start with current projections at "Reset," and then do these steps:
                          <ol type="a">
                            <li>Click on <strong>Run Projection</strong> for projected trends.</li>
                            <li>Next, cut the rate of use in half. Click on <strong>Sources</strong> on fossil fuels. Grab the highlighted green points on the curve to reduce the slope of the curve by half. For a value in 2100 at 15 Pg C/yr, check that the curve matches the figure below.</li>
                            <li>Click on <strong>Run Projection</strong>. Record the CO<sub>2</sub> level for the year 2100.</li>
                            <li>Compare CO<sub>2</sub> levels between the two runs from Steps 5d and 6c. By cutting the rate of fossil fuel in half, does that stop the increase in CO<sub>2</sub> levels in the atmosphere?</li>
                          </ol>
                        </li>
                        <li>
                          Solutions to climate change rely on not just slowing, but stopping the rate of CO<sub>2</sub> growth in the atmosphere. You can test how to do this with the model.
                          <ol type="a">
                            <li>What shape and trend of the fossil fuel curve gives no increase in CO<sub>2</sub> levels?</li>
                            <li>How does this pattern of use compare with the data from 1960-2010?</li>
                          </ol>
                        </li>
                        <li>
                          You can also simulate if the rate of fossil fuel use stayed at today's value (that is, about 9.0 Pg C/yr). Test that scenario with these steps
                          <ol type="a">
                            <li>Click on <strong>Fossil Fuels</strong> under Sources. With the highlighted green curve, adjust all points to the value of about 9.0 Pg carbon per year. The green curve will be nearly flat.</li>
                            <li>Run the projection. Record the pattern of CO<sub>2</sub> levels in the air. If you have a digital notebook, you can also capture the result.</li>
                            <li>Explain whether you think that this scenario is realistic. To do that, you can compare constant fossil fuel use to the record from 1960-2010.</li>
                          </ol>
                        </li>
                        <li>
                          Scientists are concerned that the ability of carbon sinks to keep removing CO<sub>2</sub> from the air may decrease. You can models the effect on CO<sub>2</sub> levels and temperature if carbon sinks lose their ability to take up carbon at current rates.
                          <ol type="a">
                            <li>Decide which of the sink curves you will adjust. A flat line means that the sink has stopped taking up more and more carbon each year.</li>
                            <li>Adjust the trend of your carbon sink in your model. Record your initial and changed values in your notebook.</li>
                          </ol>
                        </li>
                        <li>
                          In the steps above, you used the model to test patterns of fossil fuel use and one of the carbon sinks. You can also design your own simulation with more than one source or sink.
                          <ol type="a">
                            <li>Imagine a country and some decisions or policies that might affect sinks of carbon (land, ocean uptake) or sources of carbon (fossil fuels, land use).</li>
                            <li>Record the pattern of sources and sinks that you will simulate.</li>
                            <li>What is the effect on CO<sub>2</sub> levels in the atmosphere and global temperature?</li>
                          </ol>
                        </li>
                      </ol>

                      <div className="col-12">
                        <iframe
                          className="embed-container w-100"
                          src="https://media.bscs.org/carbonconnections/interactives/global-carbon-budget/global-carbon-budget.html"
                          title="Global carbon budget interactive"
                        />
                      </div>
                      <p>If you are unable to see the interactive, click <a href="https://media.bscs.org/carbonconnections/interactives/global-carbon-budget/global-carbon-budget.html" target="_blank" rel="noopener noreferrer">here&nbsp;<i className="fas fa-external-link-alt"></i></a> to open it in a new tab.</p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="monitoring-your-use" className="cardHeader">
                      <h2>
                        C. <span className="headerFontBlue">Monitoring Your Use</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image28.childImageSharp.fluid}
                            alt="Girls looking at light"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight rounded ml-3 mb-3 img-fluid d-none d-md-block"
                        fluid={props.data.image28.childImageSharp.fluid}
                        alt="Girls looking at light"
                      />

                      <p>
                        In <em>Carbon Connections</em>, you have been able to test different kinds of models and interactives. Some have been hands-on, and others have been on a computer. With both of these, you can see a response to an input. At the same time, it is not always as easy to understand how these models for Earth relate to you, your family, and the things you do every day.
                      </p>

                      <p>
                        The investigations you did in Units 1 and 2 with the energy-use monitor help you see how you can monitor and possibly change your energy use. Electrical energy for humans to use, on average, involves adding carbon to the atmosphere. If you can identify easy ways to use less electrical energy and lower your energy use, you emit less carbon. If many students like you do the same, you all can make a difference.
                      </p>

                      <p className="lead">Home Energy Use Activity</p>
                      <p>
                        In Units 1 and 2, you used the energy-use monitor. You looked at energy use from different kinds of light bulbs, as well as things in your classroom and school. But what about your home? That's where your family can reduce their energy use. Using less electrical energy means less carbon emissions, and savings to your family on your energy bill.
                      </p>

                      <ol start="11">
                        <li>
                          Your teacher (or maybe even you!) will bring in a common appliance from home. This could be a hairdryer, radio, cell-phone charger, power drill, room fan, or coffee-maker, for example. Do these steps for that appliance:
                          <ol type="a">
                            <li>Record the appliance name.</li>
                            <li>Describe whether the appliance uses electricity at a steady rate or in cycles. For example, refrigerators cycle on-and-off to keep a steady temperature, while a light uses energy at a steady rate. Describe this pattern.</li>
                            <li>Record the rate of energy use in watts. Remember, you can always compare its rate of energy use to that of a 40-watt light bulb.</li>
                          </ol>
                        </li>
                        <li>
                          Imagine that you got to bring home the energy-use monitor.
                          <ol type="a">
                            <li>How would you describe its functions to your family?</li>
                            <li>What device in your home would you want to check for energy use?</li>
                            <li>How would you describe to members of your family the reason why your class was monitoring energy use?</li>
                            <li>How could the energy monitor help your family to save money? Depending on the monitor you have in your classroom, it may have a cost option that can be programmed. If not, you can use your electricity bill and an estimate of &#36;0.10 per kilowatt-hour (kWh).</li>
                          </ol>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        D. <span className="headerFontBlue">Reflect and Connect</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <ol start="16">
                        <li>
                          Scientists are concerned that carbon sinks (marine and land ecosystems) may lose their ability to remove carbon from the atmosphere as they are able to do today.
                          <ol type="a">
                            <li>How would this affect CO<sub>2</sub> levels in the atmosphere?</li>
                            <li>Can you think of a reason why the ocean might lose its ability to remove carbon from the atmosphere at the same rate?</li>
                          </ol>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <ButtonNext
                ButtonText="3.4: It Starts at Home"
                NextLink="/unit-3/3.4-it-starts-at-home"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default FutureForcings33

export const query = graphql`
  query {
    image22: file(relativePath: { eq: "student-pages/unit-3/22_climate_model.png" }) {
      ...rowImage
    }
    image23: file(relativePath: { eq: "student-pages/unit-3/23_kid_swing.jpg" }) {
      ...rowImage
    }
    image24: file(relativePath: { eq: "student-pages/unit-3/24_costa_rica_border.jpg" }) {
      ...rowImage
    }
    image28: file(relativePath: { eq: "student-pages/unit-3/28_light_kids.jpg" }) {
      ...rowImage
    }
  }
`
